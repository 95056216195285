import "./style.css";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import Logo from "./assets/CHILLGUY.jpg";
import KlondikeCoin from "./assets/klondike-coin-logo.png";
import Points from "./assets/points.png";
import shortedNumber from "helpers/shortedNumber";
import anonim from "./assets/anonimous-miner.png"
import { overflowText } from "helpers/overflowText";

export const BetHistoryCarousel = ({ bets, gamemode }) => {

    const allBets = bets.sort((a, b) => b.amount - a.amount);


    return (
        <div className="bet-history-carousel">
            <CarouselProvider
                naturalSlideHeight = {70}
                naturalSlideWidth = {170}
                totalSlides={bets.length}
                touchEnabled={true}
                orientation="horizontal"
                visibleSlides={2}
            >
                <Slider classNameTray = "bet-history-carousel__slider" classNameAnimation = "bet-history-carousel__animation">
                    {allBets.map((item, idx) => {
                        return (
                            <Slide index={idx} key={idx} className = "bet-history-carousel__slide">
                                <div className="bet-history-carousel__item">
                                    <img src={item.avatar} onError = {(e) => (e.target.src = anonim)} alt="" />
                                    <div className="bet-history-carousel__item-info">
                                        <span>{overflowText(item.username)}</span>
                                        <div className="bet-history-carousel__item-info-bet">
                                            <img src={gamemode === "0" ? Points : KlondikeCoin} alt="" />
                                            <span>{shortedNumber(item.amount)}</span>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        );
                    })}
                </Slider>
            </CarouselProvider>
        </div>
    );
};
