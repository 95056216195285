import "./style.css"
import People from "./assets/people.svg"
import WebApp from "@twa-dev/sdk"
import { useGlobalStore } from "stores/global"

export const InviteBtn = ({text }) => {

    const chat_id = useGlobalStore(state => state.tg_info.chat_id);
    const language = useGlobalStore(state => state.user_info.language);

    const clickHandler = () => {
        const messageRu = "%0AХочешь стать королем бара в КЛОНДАЙК? Идем со мной в бар, жми на ссылку и заходи в игру!"
        const messageEn = "%0ADo you want to be the king of the bar in KLONDIKE? Come with me to the bar, click the link and get in the game!"

        const link = `https://t.me/share/url?text=${language === "ru" ? messageRu : messageEn}&url=${process.env.REACT_APP_BOT_URL}?start=${chat_id}&space=true`
        WebApp.openTelegramLink(link)
    }

    return <div className = "invite-btn" onClick = {clickHandler}>
            <span>{text}</span>
            <img src = {People} alt = ""/>
    </div>
}