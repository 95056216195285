import React from "react";
import "./style.css";
import PartnersImg from "./assets/Partners-img.svg";
import Loader from "components/icons/Loader";

export const Partners = ({ text, number }) => {

  console.log(number)
  return (
    <div className="partners">
      <img className="partners__img" src={PartnersImg} alt="" />
      <div className="partners__block">
        <span className="partners__text">{text}</span>
        <span className="partners__number">{number!== null ? number : <Loader fill = "#582B02" height = {24} width = {24}/>}</span>
      </div>
    </div>
  );
};
