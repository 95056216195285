import "./style.css"
import { useParams } from "react-router-dom"
import AppWidthWithContainer from "components/AppWidthContainer/AppWidthWithContainer";
import { useGlobalStore } from "stores/global";
import { HeaderCells } from "components/HeaderCells/HeaderCells";
import { BetHistoryCarousel } from "components/BetHIstoryCarousel/BetHistoryCarousel";
import WebApp from "@twa-dev/sdk";
import { BarKingTable } from "components/BarKingTable/BarKingTable";
import Points from "./assets/points.png";
import KlondikeCoin from "./assets/klondike-coin-logo.png";
import { useState, useCallback, useEffect, useRef } from "react";
import Loader from "components/icons/Loader";
import useWebSocket from 'react-use-websocket';
import Cookies from "js-cookie";
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";
import { getPageData } from "helpers/getPageData";
import klondikeCoins from "./assets/tokens-1.png"
import klondikePoints from "./assets/points-1.png"
import { useNavigate } from "react-router-dom";
import { InviteBtn } from "components/InviteBtn/InviteBtn";

export const BarKingGame = () => {
    //0 - common game for points, 1 - matchmaking game for tokens
    const { gameMode } = useParams();

    const [loading, setLoading] = useState(true)
    const [winnerFound, setWinnerFound] = useState({
        id: null,
        winner: null,
        prize: null
    })
    const [inputValue, setInputValue] = useState()
    

    const pageData = getPageData('bar_king_game');

    const navigate = useNavigate();


    const language = useGlobalStore(state => state.user_info.language);

    //room data start
    const [currentBank, setCurrentBank] = useState(0)
    const [lastBet, setLastBet] = useState(0)
    const [bets, setBets] = useState([])
    const [timerTimestamp, setTimerTimestamp] = useState(0)
    //room end

    const tg_info = useGlobalStore(state => state.tg_info );
    const avatar = useGlobalStore(state => state.user_info.avatar);
    const token_balance = useGlobalStore(state => state.user_info.token_balance );

    const balance = useGlobalStore(state => state.user_info.balance );

    const { setNotification, removeUserTokenBalance,removeUserPointsBalance } = useGlobalStore();

    const api = useGlobalStore(state => state.api);

    const infoWindow = getInfoWindowsConfig('bar_king_game');

    //coins animation start
    const coinRef = useRef(null);
    const buttonRef = useRef(null);
    const bankRef = useRef(null);

    const [animate, setAnimate] = useState(false);

    const animateCoins = () => {
            if (buttonRef.current && bankRef.current) {
              const buttonRect = buttonRef.current.getBoundingClientRect();
              const bankRect = bankRef.current.getBoundingClientRect();
        
              // Position the coin at the button initially
              coinRef.current.style.left = `${buttonRect.left+40}px`;
              coinRef.current.style.top = `${buttonRect.top}px`;
        
              setAnimate(true);
        
              // Trigger the transition to the bank
              requestAnimationFrame(() => {
                coinRef.current.style.left = `${bankRect.left}px`;
                coinRef.current.style.top = `${bankRect.top}px`;
              });
            }
    }

    //coins animation end


    //websocket start
    const getSocketUrl = useCallback(async () => {
            await api.authenticate()

            const socketUrl = process.env.REACT_APP_WS_URL
            const path = '/games/ws/2/room/'
            const queryParams = `?room_type=${gameMode}&authorization=${Cookies.get('access_token')}`

            const url = `${socketUrl}${path}${queryParams}`

            return url

      }, []);

    const [messageHistory, setMessageHistory] = useState([]);

    const { sendMessage, lastMessage, readyState } = useWebSocket(getSocketUrl, {
        onOpen: () => console.log("WebSocket connection established"),
        onMessage: (data) => messageHandler(data),
        onClose: (reason) => console.log("WebSocket connection closed with reason ",reason),
        onError: (error) => errorHandler(error),
        shouldReconnect: () => true, 
    });

    //websocket end
    useEffect(() => {
       WebApp.isVerticalSwipesEnabled = false 
    },[])


    const messageHandler = (message) => {
            const data = JSON.parse(message.data);

            console.log(data)

            if(data.type == "room_state") {
                //init state of the room
                if(data.total_pool) {
                    setCurrentBank(data.total_pool)
                }

                if(data.last_bet) {
                    setLastBet(data.last_bet)
                }

                if(data.timer_end) {
                    updateTimer(data.timer_end)
                }

                if(data.bets) {
                    setBets(data.bets)
                }
                
                

                setLoading(false)
            }

            if(data.type == "bet_update") {
                //update info about bet
                if(data.total_pool) {
                    setCurrentBank(data.total_pool)
                }

                if(data.last_bet) {
                    setLastBet(data.last_bet)
                }

                if(data.timer_end) {
                    updateTimer(data.timer_end)
                }

                if(data.user) {
                    setBets(prevBets => [...prevBets, {...data.user,amount: data.last_bet}])
                }
            }

            if(data.type == "error") {
                //error
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: data.message[language]
                }

                setNotification(notification)
            }

            if(data.type == "room_finished") {
                //winner found

                const winner = {
                    winner: data.winner,
                    prize: data.prize
                }

                setWinnerFound(winner)

                setTimeout(() => {
                    setWinnerFound({
                        id: null,
                        winner: null,
                        prize: null
                    })

                    setCurrentBank(0)
                }, 10000);
            }

            setMessageHistory((prevMessages) => [...prevMessages, data]);
    }

    const errorHandler = (error) => {
        const notification = {
            show: true,
            points: '',
            username: '',
            message: "Connection error. Please try again later"
        }

        console.log(error)

        setNotification(notification)
    } 

    //2024-12-20T00:12:58.714350
    const updateTimer = (dateString) => {

        // const date = new Date(dateString)

        // const timestamp = Math.floor(date.getTime())

       

        setTimerTimestamp(dateString * 1000)

    }

    const makeBet = ()=> {

        if(readyState !== 1) {

            const notification = {
                show: true,
                points: '',
                username: '',
                message: infoWindow.connection_unstable[language]
            }

            setNotification(notification)
            return
        }

        if(!inputValue) {
            return
        }

        if(inputValue < 10) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: gameMode == 0 ? infoWindow.min_bet_points[language] : infoWindow.min_bet_token[language] 
            }

            setNotification(notification)
            return
        }

        if(inputValue <= Number(lastBet)) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: infoWindow.last_bet_error[language]
            }

            setNotification(notification)
            return
        }

        if(inputValue == 0) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: infoWindow.zero_amount[language]
            }

            setNotification(notification)
            return
        }

        const gameBalance = gameMode == 0 ? balance : token_balance

        if(inputValue > gameBalance) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: gameMode == 0 ? infoWindow.not_enough_points_balance[language] : infoWindow.not_enough_token_balance[language]
            }

            setNotification(notification)

            //if not enough balance in game mode 1 redirect to swap page
            if(gameMode == 1) {
                setTimeout(() => {
                    navigate('/swap')
                }, 2000);
            }

            return
        }

        const msg = {
            "type": "make_bet",
            "amount": inputValue
        }

        animateCoins()

        sendMessage(JSON.stringify(msg))

        const notification = {
            show: true,
            points: '',
            username: '',
            message: infoWindow.bet_accepted[language]
        }

        setNotification(notification)

        if(gameMode == 0) {
            removeUserPointsBalance(inputValue)
        } else {
            removeUserTokenBalance(inputValue)
        }
    
        


    }

    const handleBlur = () => {
        // Return the page position after keyboard closes
        window.scrollTo(0, 0);
      };

    const changeHandler = (e) => {
        e.preventDefault()
        setInputValue(e.target.value)
    }


    

    


    return <AppWidthWithContainer className = "bar-king-game">
            <HeaderCells image = {avatar} text = {tg_info.tg_name} tokenBalance = {token_balance}/>
            
            <div className = "bar-king-game__content">
                <span className = "bar-king-game__bet-history">{pageData.bet_history[language]}</span>

                {loading ? <div className = "bar-king-game__loader"><Loader fill = "#E7A510" height = {30} width = {30}/></div> 
                :
                currentBank > 0 ? 
                <BetHistoryCarousel bets = {bets} gamemode = {gameMode}/>
                :
                <span className = "bar-king-game__no-bet">{pageData.no_bets[language]}</span>
                }
                <BarKingTable bankRef = {bankRef} language = {language} pageData = {pageData} timer = {timerTimestamp} winnerFound = {winnerFound} loading = {loading} currentBank = {currentBank} lastBet = {lastBet} gamemode = {gameMode}/>
                <div className = "bar-king-table__bet-section">
                    <div className = "bar-king-table__bet-input">
                       <div className = "bar-king-table__bet-input__input">
                            <img src = {gameMode === "0" ? Points : KlondikeCoin} alt = ""/>
                            <input placeholder = "0" type = "number" pattern="[0-9]*[.,]?[0-9]*"  onBlur = {handleBlur} onChange = {changeHandler} value = {inputValue} inputmode="decimal" />
                        </div>
                        <div className = { "bar-king-table__bet-input__balance"}>
                            <span>{pageData.balance[language]}</span>
                            <span>{gameMode == 0 ? balance : token_balance}</span>
                        </div>
                    </div>
                
                    <div className = "bar-king-table__bet-button" onClick = {makeBet} ref = {buttonRef}>
                        <span>{pageData.make_bet[language]}</span>
                    </div>
                </div>
                <InviteBtn text = {pageData.invite[language]}/>
                
                <br></br>
                <img ref = {coinRef} onTransitionEnd={() => setAnimate(false)} class = {`bet-coins ${animate ? "visible" : "hidden"}`} src = {gameMode === "0" ? klondikePoints: klondikeCoins} alt = "" />

            </div>
    </AppWidthWithContainer>
}