import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css";
import App from "./app/App";

import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { BrowserRouter as Router } from "react-router-dom";


// import eruda from "eruda";

// eruda.init()

const root = ReactDOM.createRoot(document.getElementById("root"));


  root.render(
    
     <TonConnectUIProvider 
     manifestUrl="https://game.klondike-farm.app/tonManifest.json" 
     actionsConfiguration={{
      twaReturnUrl: process.env.REACT_APP_WEBAPP_URL
  }}
     >
      <Router>
      <App/>
      </Router>
      </TonConnectUIProvider>
     
    
  );

